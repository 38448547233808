interface ICard {
  title: string;
  subtitle: string;
  imageSrc: string;
}

export default function Card({ title, subtitle, imageSrc }: ICard) {
  return (
    <div className='flex flex-row  gap-1 items-center justify-start'>
      <img src={imageSrc} alt={`${title} Card`} width={95} height={95} />
      <div>
        <h5 className='font-bold text-[17px]'>{title}</h5>
        <p className='text-[#C0C1C5] text-[15px]'>{subtitle}</p>
      </div>
    </div>
  );
}
